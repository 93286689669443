const env = {};

env.authApi = process.env.REACT_APP_URL_AUTH_API;
env.masterApi = process.env.REACT_APP_URL_MASTER_API;
env.managementApi = process.env.REACT_APP_URL_MANAGEMENT;
env.financialApi = process.env.REACT_APP_URL_FINANCIAL;
env.apiPrefixV1 = process.env.REACT_APP_API_PREFIX_V1;
env.googleMapsApiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
env.clientId = process.env.REACT_APP_CLIENT_ID;
env.clientSecret = process.env.REACT_APP_CLIENT_SECRET;
env.notifServer = process.env.REACT_APP_NOTIF_SERVER;

export default env;
